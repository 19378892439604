import content from 'content.json';
import React, { useMemo } from 'react';
import { Image } from 'react-bootstrap';
import { useBookingStore } from 'store/booking/reducer';
import { BookingType, loyalType } from 'types/booking';

import SocialIcons from './components/SocialIcons';
import { parseCoreUser } from 'services/utils/user-utils';

const { inbox } = content;

interface IClientName {
    client: any;
    selected: BookingType | null;
    priceModel: string;
}

const ClientName: React.FC<IClientName> = ({ client, selected, priceModel }) => {
    const { fullName } = useMemo(
        () => parseCoreUser(client),
        [client]
    );
    const bookingMetadata = useBookingStore((store) => store.bookingMetadata);
    const getPlanName = (plan: number | undefined) =>
        plan != undefined ? bookingMetadata.plans[plan].text : null;

    const getPlan = () => {
        if (selected && getPlanName(selected?.session.plan)) {
            const planClassName = getPlanName(selected?.session.plan);
            return (
                <div className={`client-plan ${planClassName}`}>
                    {getPlanName(selected?.session.plan)} {priceModel}
                </div>
            );
        }
    };

    return (
        <div className="client-info">
            <label className="client-name">{fullName}</label>
            {selected?.client?.loyalty && (
                <Image
                    className="loyalty"
                    src={inbox.icons[selected.client.loyalty as loyalType]}
                />
            )}
            {getPlan()}
            <SocialIcons client={client} />
        </div>
    );
};
export default ClientName;
