import './style.scss';

import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { sentryException } from 'services/SentryLogging';

import content from 'content.json';

const { modal } = content;

const RemoveItem = ({ removeItem, data, toggleModal }) => {
    const onSubmit = () => {
        try {
            removeItem(data.uuid);
            toggleModal();
        } catch (e) {
            sentryException(e, "Couldn't remove item");
        }
    };

    return (
        <span className="delete-item">
            <Modal.Header>
                <p>{modal.deleteItem.title}</p>
            </Modal.Header>

            <Modal.Footer>
                <Button variant="light" onClick={() => toggleModal()}>
                    {modal.deleteItem.cancel}
                </Button>
                <Button variant="dark" onClick={onSubmit}>
                    {modal.deleteItem.submit}
                </Button>
            </Modal.Footer>
        </span>
    );
};

export default RemoveItem;
