const {
    REACT_APP_ENV,
    REACT_APP_SENTRY_DSN,
    REACT_APP_API_GW_PATH,
    REACT_APP_MIXPANEL_TOKEN,
    REACT_APP_LAUNCHDARKLEY_SDK_KEY
} = process.env;

const conf = {
    appEnv: REACT_APP_ENV,
    'api-gw': REACT_APP_API_GW_PATH,
    mixpanelToken: REACT_APP_MIXPANEL_TOKEN,
    launchDarklyClientSideId: REACT_APP_LAUNCHDARKLEY_SDK_KEY,
    sentryDsn: REACT_APP_SENTRY_DSN
};

const Config = {
    get: (prop: keyof typeof conf) => conf[prop]!,
    getEnvironment: (): string => Config.get('appEnv'),
    isProd: () => Config.getEnvironment() === 'production',
    isDev: () => !Config.isProd(),
    // This function is not related to configuration and should be moved to a separate util
    isMobile: () => 
        /android|webos|iphone|ipad/i.test(
            navigator.userAgent.toLowerCase()
        ) || window.screen.width <= 768
};

export default Config;
