import './style.scss';

import { useIsMobile } from 'hooks';
import React, { useEffect, useRef, useState } from 'react';
import { Badge, Col, Container, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Slider from 'react-slick';
import { MIXPANEL_EVENTS, trackEvent } from 'services/mixpanel';

import { Lightbox, Loader, Page } from 'components';
import { classNameVariant } from 'constants/global-styles';
import content from 'content.json';
import { Formatter } from 'services';
import { CustomWindow, ItemType, OutfitType } from 'types/item';
import { Client, Stylist } from 'types/user';
import { ItemDetails, ItemImages, MoreItems, ShopButton } from './components';
declare const window: CustomWindow;

const { item: texts } = content;

interface IItem {
    styleLoading: boolean;
    user: Client | Stylist;
    outfit: OutfitType;
    item: ItemType;
    loadOutfit: (uuid: string) => void;
    loadItem: (uuid: string) => void;
    updateItem: (item: ItemType | undefined) => void;
    location: Location;
    history: any;
    currentItem: string;
    setCurrentItem: (item: string) => void;
}

const Item: React.FC<IItem> = ({
    styleLoading,
    user,
    outfit,
    item,
    loadOutfit,
    loadItem,
    updateItem,
    location,
    history,
    currentItem,
    setCurrentItem
}) => {
    const { outfit_uuid, item_uuid }: { outfit_uuid: string; item_uuid: string } = useParams();
    const [loading, setLoading] = useState(false);
    const [context, setContext] = useState('feed');
    const [source, setSource] = useState('');
    const [mainItem, setMainItem] = useState<ItemType>();
    const [sizes, setSizes] = useState<string[] | undefined>([]);
    const [moreItems, setMoreItems] = useState<ItemType[]>([]);
    const [lightbox, setLightbox] = useState(false);
    const [itemPicture, setItemPicture] = useState<string[]>([]);
    const sliderRef = useRef<Slider>(null);
    const isMobile = useIsMobile();
    const itemContext = Formatter.queryString(location.search).context;
    const isGift = Formatter.queryString(location.search).context === 'gifts';
    const itemSource = itemContext !== 'feed' ? itemContext : 'feed';

    const slickMove = (ref: React.RefObject<Slider>) => ref?.current?.slickGoTo(0);

    useEffect(() => {
        if (Formatter.queryString(location.search).source)
            setSource(Formatter.queryString(location.search).source);
        if (item_uuid) loadItem(item_uuid);
        if (outfit_uuid) loadOutfit(outfit_uuid);
        if (history.location) {
            const path = history.location.pathname.split('/')[1];
            setContext(path === 'item' ? '' : path);
        }
        window.scrollTo(0, 0);
    }, []);
    useEffect(() => {
        window.item = item;
        if (item?.sizes) setSizes(item.sizes.filter((size) => size.length > 0));
    }, [item]);

    useEffect(() => {
        if (user && user.user_uuid) window.user_uuid = user.user_uuid;
    }, [user]);

    useEffect(() => {
        if (sliderRef.current) {
            slickMove(sliderRef);
            if (Array.isArray(item?.sizes))
                setSizes(item?.sizes.filter((size: string | string[]) => size.length > 0));
        }
        if (mainItem?.additional_pictures)
            setItemPicture([mainItem.picture, ...mainItem.additional_pictures]);
        else if (mainItem?.picture) setItemPicture([mainItem.picture]);
    }, [mainItem]);

    useEffect(() => {
        if (
            (outfit.unique === outfit_uuid ||
                outfit.unique === Formatter.queryString(location.search).outfit_uuid) &&
            outfit.items
        ) {
            const outfitItem = outfit.items.find((it) => it.unique === item_uuid);
            item = { ...item, type: outfitItem?.type, item_type: outfitItem?.item_type };

            trackEvent({
                name: MIXPANEL_EVENTS.LOOK_VIEWS,
                properties: {
                    'Look UUID': outfit.uuid,
                    'Look source': itemSource,
                    'Stylist name': outfit.owner.first_name.concat(' ', outfit.owner.last_name),
                    'Stylist UUID': outfit.owner.uuid
                }
            });
            if (item) {
                if (item.item_uuid && currentItem !== item.item_uuid) {
                    setCurrentItem(item.item_uuid);

                    trackEvent({
                        name: MIXPANEL_EVENTS.ITEM_VIEWS,
                        properties: {
                            'Item UUID': item.item_uuid,
                            'Item source': itemSource,
                            'Item brand': item.brand_name
                        }
                    });
                    if (itemSource === 'session')
                        trackEvent({
                            name: MIXPANEL_EVENTS.ITEM_CLICKS,
                            properties: {
                                'Item UUID': item.item_uuid,
                                'Item source': 'session',
                                'Item brand': item.brand_name
                            }
                        });
                }
                setMainItem({
                    ...item,
                    retail_price: Formatter.priceFormatter(
                        typeof (item?.retail_price === 'string')
                            ? item?.retail_price
                            : item?.retail_price.toString()
                    ),
                    sale_price: Formatter.priceFormatter(item?.sale_price?.toString() ?? ''),
                    isGift
                });
            }
            updateItem(item);
            setMoreItems(outfit.items.filter((it) => it.unique !== item_uuid));
        }
    }, [outfit]);

    useEffect(() => {
        if (outfit_uuid) {
            if (item.uuid && item.uuid !== mainItem?.uuid) loadOutfit(outfit_uuid);
        } else {
            if (item.uuid)
                setMainItem({
                    ...item,
                    retail_price: Formatter.priceFormatter(
                        typeof (item?.retail_price == 'string')
                            ? item?.retail_price
                            : item?.retail_price.toString()
                    ),
                    sale_price: Formatter.priceFormatter(item?.sale_price?.toString() ?? ''),
                    isGift
                });
        }
    }, [item]);

    useEffect(() => {
        setLoading(styleLoading);
    }, [styleLoading]);

    const openItem = ({
        uuid,
        item_uuid,
        unique,
        sku
    }: {
        uuid: string;
        item_uuid: string;
        unique: string;
        sku: string | string[];
    }) => {
        const queryParams = location.search.length
            ? Object.keys(Formatter.queryString(location.search))
                  .map((key) => `${key}=${Formatter.queryString(location.search)[key]}`)
                  .join('&')
            : '';
        loadItem(uuid || item_uuid || unique || sku[0]);
        history.push(
            `/${context ? `${context}/` : ''}${outfit_uuid ? `outfit/${outfit_uuid}/` : ''}item/${
                uuid || item_uuid || unique || sku[0]
            }${queryParams.length ? `?${queryParams}` : ''}`
        );
    };

    return (
        <Page footer={false} header={!isMobile} className="item-page">
            {loading && <Loader />}
            {lightbox && (
                <Lightbox
                    variant="light"
                    opacity={1}
                    photos={itemPicture}
                    selected={0}
                    closePosition="right"
                    onClose={() => setLightbox(false)}
                />
            )}
            <Container>
                <Row className="header">
                    <Col>
                        {outfit && (
                            <div
                                className={`back-btn back-${
                                    context ? context : outfit_uuid ? 'outfit' : ''
                                }`}
                                onClick={() =>
                                    source
                                        ? history.push(`/${source?.split('_').join('/')}`)
                                        : outfit_uuid
                                        ? history.push(`/${context}/outfit/${outfit_uuid}`)
                                        : history.goBack()
                                }
                            />
                        )}
                    </Col>
                </Row>
                <Row className="item">
                    {!loading && (
                        <>
                            <Col xs={12} sm={6}>
                                {(mainItem?.uuid || mainItem?.unique) && (
                                    <ItemImages
                                        isMobile={isMobile}
                                        pictures={mainItem.additional_pictures}
                                        mainPicture={mainItem.picture}
                                        sliderRef={sliderRef}
                                        setLightbox={setLightbox}
                                    />
                                )}
                            </Col>
                            <Col xs={12} sm={6} className="item-details">
                                <Row>
                                    <Container fluid>
                                        <ItemDetails item={mainItem} sizes={sizes} />

                                        <ShopButton
                                            variant={classNameVariant.fullScreen}
                                            item={mainItem}
                                            sizes={sizes}
                                        />

                                        {mainItem?.isGift && mainItem?.tags && (
                                            <div className="tags">
                                                {mainItem?.tags.map((filter) => (
                                                    <Badge variant="dark" key={filter}>
                                                        {filter}
                                                    </Badge>
                                                ))}
                                            </div>
                                        )}
                                    </Container>
                                </Row>
                            </Col>
                            <Col xs={12}>
                                <div className="description">
                                    {mainItem?.description || mainItem?.shortDescription}
                                </div>

                                <ShopButton
                                    variant={classNameVariant.smallScreen}
                                    item={mainItem}
                                    sizes={sizes}
                                />
                            </Col>
                        </>
                    )}
                </Row>

                {moreItems.length > 0 && <MoreItems items={moreItems} onClick={openItem} />}
            </Container>
            <div className="disclosure">{texts.disclosure}</div>
        </Page>
    );
};

export default (props: any) => <Item {...props} />;
