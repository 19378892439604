import './style.scss';

import { useWithDispatch } from 'hooks';
import React, { useEffect, useState } from 'react';
import { Cookies } from 'services';
import { toggleModal } from 'store/ui/actions';
import { useUIStore } from 'store/ui/reducer';
import { useUserStore } from 'store/user/reducer';
import { ModalsKeys } from 'types/helpers';

import { Modal, NavBar } from '..';
import ModalContent from '../ModalContent';
interface IPage {
    header?: boolean;
    footer?: boolean;
    fixedHeader?: string | boolean;
    className?: string;
    children?: React.ReactNode;
}
const Page: React.FC<IPage> = ({
    header = true,
    fixedHeader = 'top',
    footer = true,
    children,
    className = ''
}) => {
    const [canLoad, setCanLoad] = useState(false);
    const [userChecked, setUserChecked] = useState({});
    const [firstLoad, setFirstLoad] = useState(true);
    const userData = useUserStore((store) => store.user);
    const modalData = useUIStore((store) => store.modalData);
    const modalType = useUIStore((store) => store.modalType) ?? 'Signin';
    const showModal = useUIStore((store) => store.indicators.showModal);
    const toggleModalAction = useWithDispatch(toggleModal);
    const Content = ModalContent[modalType];

    useEffect(() => {
        if (userData !== null) {
            setFirstLoad(false);
        }
        const canLoadPage = !canLoad
            ? userData &&
              Object.keys(userChecked).length > 1 &&
              (window.location.pathname !== '/' || (window.location.pathname === '/' && !firstLoad))
            : true;
        setCanLoad(canLoadPage ?? false);
    }, [userData, userChecked]);

    useEffect(() => {
        const userCheck = Cookies.get('user');
        if (Object.keys(userCheck).length < 1 || window.location.pathname.includes('chat')) {
            setCanLoad(true);
        }
        setUserChecked(userCheck);
    }, []);

    return (
        <div style={{ display: 'contents' }}>
            {canLoad ? (
                <div
                    className={`page ${header ? 'with-header' : ''} ${
                        header && fixedHeader ? 'fixed-header' : ''
                    } ${footer ? 'width-footer' : 'no-footer'} ${className}`}
                >
                    {header && (
                        <NavBar
                            onClick={(type: ModalsKeys) => toggleModalAction({ type })}
                            fixed={fixedHeader}
                        />
                    )}
                    {children}

                    {Content && (
                        <Modal show={showModal} onHide={toggleModalAction} className={modalType}>
                            <Content data={modalData?.data} />
                        </Modal>
                    )}
                </div>
            ) : null}
        </div>
    );
};

export default Page;
