import jsCookies from 'js-cookie';

const Cookies = {
    set: (name, value, options = {}) => {
        const val = JSON.stringify(value ?? '')
        jsCookies.set(
            name, 
            val, 
            { 
                path: '/app', 
                expires: new Date('9999-12-31T00:00:00'), 
                ...options 
            });
    },
    get: (name) => {
        const cookie = jsCookies.get(name);
        let value;
        try {
            value = JSON.parse(cookie);
        } catch (e) {
            value = cookie ? cookie : {};
        }
        return value;
    },
    remove: (name, options) => {
        jsCookies.remove(name, { path: '/app' });
        jsCookies.remove(name, options);
    }
};

export default Cookies;
