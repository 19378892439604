import { ProfilePhoto } from 'components';
import content from 'content.json';
import { useWithDispatch } from 'hooks';
import React from 'react';
import { Button, Col, Dropdown, Image, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { sendMessage } from 'services/chat-service';
import { isArchive } from 'services/utils/bookings-utils';
import { toggleModal } from 'store/ui/actions';
import { useUserStore } from 'store/user/reducer';
import { BookingType, ButtonActionType } from 'types/booking';

import ClientName from './ClientName';

const { inbox } = content;

interface IChat {
    client: any;
    selectedBooking: BookingType | null;
    showDetails: boolean;
    setShowDetails: (flag: boolean) => void;
    chatLoading: boolean;
    loading: boolean;
    chatRef: React.Ref<any>;
    onArchive: (booking: BookingType | null) => void;
    priceModel: string;
}

const Chat: React.FC<IChat> = ({
    client,
    selectedBooking,
    showDetails,
    setShowDetails,
    chatLoading,
    chatRef,
    onArchive,
    priceModel
}) => {
    const user = useUserStore((store) => store.user);
    const toggleModalAction = useWithDispatch(toggleModal);
    const isArchiveBookings = isArchive(selectedBooking?.session.type ?? '');
    const showEndSession = selectedBooking?.actions.find(
        (action) => action.key === ButtonActionType.requestEndSession
    );

    const sendUploadMessage = async () => {
        await sendMessage({
            stylist_uuid: user?.user_uuid ?? '',
            client_uuid: selectedBooking?.client.uuid ?? '',
            type: 'text',
            sender: 'stylist',
            content: inbox.bookings.uploadMessage
        });
    };

    return (
        <Col className="chat">
            {client && (
                <div className="chat-header">
                    <ProfilePhoto user={client} />
                    <ClientName
                        client={client}
                        selected={selectedBooking}
                        priceModel={priceModel}
                    />

                    <span>
                        {selectedBooking && !isArchiveBookings && (
                            <Dropdown drop="down" className="more-dropdown">
                                <Dropdown.Toggle
                                    id="booking-dropdown"
                                    variant="light"
                                    className="more-btn"
                                >
                                    <div className="more-dots" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={sendUploadMessage}>
                                        {inbox.bookings.uploadCloset}
                                    </Dropdown.Item>
                                    {showEndSession ? (
                                        <Dropdown.Item
                                            onClick={() =>
                                                toggleModalAction({
                                                    type: 'EndSession',
                                                    data: selectedBooking
                                                })
                                            }
                                        >
                                            {inbox.bookings.endSession}
                                        </Dropdown.Item>
                                    ) : (
                                        <Dropdown.Item />
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                        )}

                        {selectedBooking && isArchiveBookings ? (
                            <Button
                                variant="light"
                                className="unarchive"
                                onClick={() => onArchive(selectedBooking)}
                            >
                                {inbox.bookings.unarchive}
                            </Button>
                        ) : (
                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip id="archive">
                                        {inbox.bookings.tooltips.archive}
                                    </Tooltip>
                                }
                            >
                                <div
                                    className={`archive ${chatLoading ? 'disabled' : ''}`}
                                    onClick={() => onArchive(selectedBooking)}
                                />
                            </OverlayTrigger>
                        )}
                        {selectedBooking && isArchiveBookings && (
                            <p className="unarchive-comment">
                                <Image
                                    src={inbox.icons.unarchiveArrow}
                                    onClick={() => onArchive(selectedBooking)}
                                />
                                {inbox.bookings.unarchiveComment}
                            </p>
                        )}

                        {!showDetails && (
                            <OverlayTrigger
                                placement="bottom"
                                overlay={
                                    <Tooltip id="details">
                                        {inbox.bookings.tooltips.details}
                                    </Tooltip>
                                }
                            >
                                <p className="details-toggle" onClick={() => setShowDetails(true)}>
                                    {inbox.bookings.details}
                                </p>
                            </OverlayTrigger>
                        )}
                    </span>
                </div>
            )}
            <div className="chat-container" ref={chatRef} />
        </Col>
    );
};
export default Chat;
