import { ShopFilter } from 'types/item';

import content from 'content.json';

const { styling } = content;

export const addItemAttributes = (array: string[], isSingleSelect = false) => {
    return array.map((item) => ({ key: item, text: item, single_select: isSingleSelect }));
};

export const colorsMaps = (colorsArray: ShopFilter[]) => {
    return colorsArray.map((color) => ({
        key: color.text,
        text: color.text,
        picture: color.picture
    }));
};

export const budgetOptions = {
    min: {
        key: 'minPrice',
        text: styling.filters.budget_min
    },
    max: {
        key: 'maxPrice',
        text: styling.filters.budget_max
    }
};

export const budgetStoreOptions = {
    min: {
        key: 'min_budget',
        text: styling.filters.budget_min
    },
    max: {
        key: 'max_budget',
        text: styling.filters.budget_max
    }
};

export const isLocationDressingRoom = () => window.location.pathname.includes('dressing-room');

export const isButtonDisable = (description: string, disable: boolean) =>
    !description.length || description.split(' ').length - 1 === description.length || disable;
