import './style.scss';

import { Loader, Masonry } from 'components';
import content from 'content.json';
import { useIsMobile } from 'hooks';
import React, { useEffect, useState } from 'react';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { Cookies, User } from 'services';
import { sentryException } from 'services/SentryLogging';
import { useProfileStore } from 'store/profile/reducer';
import { ModalData } from 'store/ui/types';
import { useUserStore } from 'store/user/reducer';
import { Photo } from 'types/styling';
import { CustomError } from 'types/utils';

import Empty from './Empty';

const { profile: texts } = content;

interface IPhotos {
    loading: boolean;
    photos: Photo[];
    error: CustomError;
    loadPhotos: (params: { from: number }) => void;
    toggleModal: (data?: ModalData) => void;
}
const Photos: React.FC<IPhotos> = ({ loading, photos, error, loadPhotos, toggleModal }) => {
    const [gridItems, setGridItems] = useState<Photo[]>([]);
    const isMobile = useIsMobile();
    const user = useUserStore((store) => store.user);
    const gridSize = useProfileStore((store) => store.gridSize);

    useEffect(() => {
        if (user) fetchData({});
    }, [user]);

    useEffect(() => {
        if (error && error.profile)
            toggleModal({ type: 'Error', data: { error: texts.errors.photos } });
    }, [error]);

    useEffect(() => {
        if (!loading && photos) setGridItems(photos);
    }, [photos]);

    const fetchData = (params: any) =>
        loadPhotos({
            from: params ? params.from : photos ? photos.length : 0
        });

    const onPictureChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        try {
            const user = Cookies.get('user');
            const data = new FormData();
            e?.target?.files && data.append('picture', e?.target?.files[0]);
            await User.photo({
                user_uuid: user.uuid,
                data,
                token: user.token
            });
            fetchData({ from: 0 });
        } catch (e) {
            sentryException(e as Error, "Cannot change user's picture");
        }
    };

    const onRemove = (photo: Photo) => toggleModal({ type: 'RemovePhoto', data: photo });

    return (
        <Container className="profile-photos" fluid>
            {loading && <Loader />}
            <Row>
                <Col id="results" className="results">
                    {gridItems.length ? (
                        <Masonry columns={isMobile ? 2 : gridSize === 'small' ? 5 : 3}>
                            {gridItems.map((item) => (
                                <PhotoItem
                                    key={item.photo_uuid}
                                    size={gridSize}
                                    item={item}
                                    onRemove={() => onRemove(item)}
                                    onClick={() =>
                                        toggleModal({ type: 'Photo', data: item.photo_large })
                                    }
                                />
                            ))}
                        </Masonry>
                    ) : (
                        <Empty section="photos" />
                    )}

                    <div className="add-btn">
                        {!gridItems.length && (
                            <div className="empty-add-text">
                                <p>{texts.addPhoto}</p>
                                <Image src={texts.addArrow} />
                            </div>
                        )}
                        <label htmlFor="upload-image">+</label>
                        <input
                            type="file"
                            id="upload-image"
                            accept="image/x-png,image/gif,image/jpeg"
                            onChange={onPictureChange}
                        />
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

const PhotoItem: React.FC<{
    item: Photo;
    onRemove: () => void;
    onClick: () => void;
    size: string;
}> = ({ item, onRemove, onClick, size }) => (
    <div className={`photo-item ${size}`}>
        <div className="trash" onClick={onRemove} />
        <Image src={item.photo_large} alt="" onClick={onClick} />
    </div>
);
export default Photos;
