import axios from 'axios';

import Config from './Config';
import { getUserToken } from './utils/token-utils';

const apiRoot = Config.get('api-gw');

const Stylist = {
    urls: {
        clients: (stylist_id: string) => `${apiRoot}proxy/stylist/${stylist_id}/clients`
    },
    all: (params: Record<string, any>) =>
        axios.get(`${apiRoot}proxy/stylist/readAll`, {
            params,
            headers: { token: getUserToken() }
        }),
    get: (stylist_id: string, user_uuid: string | undefined = undefined) =>
        axios.get(
            `${apiRoot}proxy/stylist/${stylist_id}/read`,
            user_uuid
                ? {
                      params: { user_uuid },
                      headers: { token: getUserToken() }
                  }
                : {}
        ),
    reviews: (stylist_id: string, from: number, count: number) =>
        axios.get(`${apiRoot}proxy/stylist/${stylist_id}/reviews`, {
            params: { from, count },
            headers: { token: getUserToken() }
        }),
    getLatestReviews: (limit: number) =>
        axios.get(`${apiRoot}review/getLatestReviews`, {
            params: { limit },
            headers: { token: getUserToken() }
        }),
    review: (stylist_id: string, review: string) =>
        axios.post(`${apiRoot}proxy/stylist/${stylist_id}/review`, review, {
            headers: { token: getUserToken() }
        }),
    names: () =>
        axios.get(`${apiRoot}proxy/stylist/all/names`, {
            headers: { token: getUserToken() }
        }),
    looks: (stylist_id: string, params: Record<string, any>) =>
        axios.get(`${apiRoot}proxy/stylist/${stylist_id}/looks`, {
            params: params,
            headers: { token: getUserToken() }
        }),
    bookings: (stylist_id: string, params: Record<string, any>, channel_sid: string) =>
        axios.get(
            `${apiRoot}proxy/stylist/${stylist_id}/bookings${
                channel_sid ? `/${channel_sid}` : ''
            }`,
            { params, headers: { token: getUserToken() } }
        ),
    clients: (stylist_id: string, params: Record<string, any>) =>
        axios.get(`${apiRoot}proxy/stylist/${stylist_id}/clients`, {
            params,
            headers: { token: getUserToken() }
        }),
    archiveSession: (stylist_uuid: string, channel_id: string) =>
        axios.put(
            `${apiRoot}proxy/user/${stylist_uuid}/channels/${channel_id}/moveToArchive`,
            null,
            { headers: { token: getUserToken() } }
        ),
    unarchiveSession: (stylist_uuid: string, channel_id: string) =>
        axios.put(
            `${apiRoot}proxy/user/${stylist_uuid}/channels/${channel_id}/moveToInbox`,
            null,
            { headers: { token: getUserToken() } }
        ),
    important: ({
        channel_id,
        params,
        token
    }: {
        channel_id: string;
        params: Record<string, any>;
        token: string;
    }) =>
        axios.put(`${apiRoot}proxy/channel/${channel_id}/importantFlag`, params, {
            headers: { token: getUserToken() ?? token }
        }),
    createSession: ({ token, body }: { token: string; body: Record<string, any> }) =>
        axios.post(`${apiRoot}proxy/twilio/channel`, body, {
            headers: { token: getUserToken() ?? token }
        }),
    endSession: (channel_id: string, token: string) =>
        axios.put(`${apiRoot}proxy/twilio/channel/${channel_id}/attemptEnding`, null, {
            headers: { token: getUserToken() ?? token }
        }),
    sendMessage: ({
        stylist_uuid,
        client_uuid,
        type,
        sender,
        content,
        token
    }: {
        stylist_uuid: string;
        client_uuid: string;
        type: string;
        sender: string;
        content: string;
        token?: string;
    }) =>
        axios.post(
            `${apiRoot}proxy/twilio/message`,
            { stylist_uuid, client_uuid, type, sender, content },
            { headers: { token: getUserToken() ?? token } }
        ),
    updateFollowup: (enabled: boolean, channel_id: string, token: string | undefined = undefined) =>
        axios.put(
            `${apiRoot}proxy/channel/${channel_id}/followUpFlag`,
            { enabled },
            { headers: { token: getUserToken() ?? token } }
        ),
    store: (stylist_id: string, params: Record<string, any>) =>
        axios.get(`${apiRoot}proxy/stylist/${stylist_id}/store`, {
            params,
            headers: { token: getUserToken() }
        }),
    getAvailability: (params: { key: string; value: any }) =>
        axios.post(`${apiRoot}availability/item`, params, {
            headers: { token: getUserToken() }
        }),
    getShopItems: (params: Record<string, any>) =>
        axios.get(`${apiRoot}inventory/search?`, {
            params,
            headers: { token: getUserToken() }
        }),
    shop: (params: Record<string, any>) =>
        axios.get(`${apiRoot}proxy/item/shop`, {
            params,
            headers: { token: getUserToken() }
        }),
    inspiration: (params: Record<string, any>) =>
        axios.get(`${apiRoot}proxy/item`, {
            params,
            headers: { token: getUserToken() }
        }),
    shopFiltersNew: (params: Record<string, any>) =>
        axios.get(`${apiRoot}inventory/search/filters`, {
            params,
            headers: { token: getUserToken() }
        }),
    shopFilters: (params: Record<string, any>) =>
        axios.get(`${apiRoot}proxy/item/shopFilters`, {
            params,
            headers: { token: getUserToken() }
        }),
    storeFilters: (stylist_uuid: string, params: Record<string, any>) =>
        axios.get(`${apiRoot}proxy/stylist/${stylist_uuid}/storeFilters`, {
            params,
            headers: { token: getUserToken() }
        }),
    inspirationTags: (params: Record<string, any>) =>
        axios.get(`${apiRoot}proxy/tag/inspiration`, {
            params,
            headers: { token: getUserToken() }
        }),
    favorites: (stylist_uuid: string, params: Record<string, any>) =>
        axios.get(`${apiRoot}proxy/stylist/${stylist_uuid}/favoriteItems`, {
            params,
            headers: { token: getUserToken() }
        }),
    favorite: (stylist_uuid: string, item: Record<string, any>) =>
        axios.post(
            `${apiRoot}proxy/stylist/${stylist_uuid}/item/favorites`,
            {
                item
            },
            { headers: { token: getUserToken() } }
        ),
    moodboard: (data: Record<string, any>) =>
        axios.post(`${apiRoot}proxy/outfit/moodboard`, data, {
            headers: { token: getUserToken() }
        }),
    look: ({ data }: { data: Record<string, any> }) =>
        axios.post(`${apiRoot}proxy/outfit/collection`, data, {
            headers: { token: getUserToken() }
        }),
    revision: ({ outfit_uuid, data }: { outfit_uuid: string; data: Record<string, any> }) =>
        axios.post(`${apiRoot}proxy/outfit/${outfit_uuid}/revision`, data, {
            headers: { token: getUserToken() }
        }),
    storeRemove: (stylist_id: string, items_uuids: Array<string>) =>
        axios.put(
            `${apiRoot}proxy/stylist/${stylist_id}/items/archiveByIds`,
            { items_uuids },
            { headers: { token: getUserToken() } }
        ),
    chatEntered: (channel_id: string) =>
        axios.post(`${apiRoot}booking/event/chat-entered/${channel_id}`)
};

export default Stylist;
