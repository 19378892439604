import { useEffect, useState } from 'react';
import { Card, Col, Collapse, Container, Row } from 'react-bootstrap';

import FilterItem from './FilterItem';

const Filter = ({
    filter: { key = '', value = '', filter_options = [], filters = [] },
    selected = [],
    showSelection = false,
    isCollapsed,
    onClick,
    onChange,
    onClear,
    disabled = false,
    type,
    categorySingleSelect,
    categoryHasFilterSelected,
    isfilterSubmited = false,
    isSort = false
}) => {
    const MAX_OPTIONS = 8;
    const [showAll, setShowAll] = useState(false);
    const [filterOptions, setFilterOptions] = useState([]);
    const isInbox = window.location.pathname === '/inbox';
    const isSingleSelect = (item) =>
        item.single_select
            ? item.single_select
            : categorySingleSelect
            ? categorySingleSelect
            : null;

    useEffect(() => {
        if (filter_options.length > 0 || filters.length > 0) {
            setFilterOptions(filter_options.length > 0 ? filter_options : filters);
        }
    }, [filter_options, filters]);

    return (
        <Card
            className={`filter-row ${isCollapsed && filterOptions.length > 0 ? 'active' : ''} ${
                disabled ? 'disabled' : ''
            } ${key.toLowerCase()}`}
        >
            <div
                className={`filter ${isInbox ? 'inbox' : ''}`}
                onClick={() => onClick(isCollapsed ? '' : key)}
            >
                {value}
                {categoryHasFilterSelected && isInbox && !isSort && (
                    <div className="category-selected"></div>
                )}
                <span className="expand-sign" />
                {showSelection && (
                    <div className="selection">
                        {selected
                            .map((selection) => {
                                if (selection) return selection.text;
                            })
                            .join(',')}
                    </div>
                )}
            </div>
            <Collapse in={key === 'budget' ? isCollapsed : isCollapsed && filterOptions.length > 0}>
                <Card.Body>
                    <Container fluid>
                        {filterOptions && filterOptions.length > 0 && type === 'checkbox' ? (
                            <span>
                                {filterOptions
                                    .slice(0, showAll ? filterOptions.length : MAX_OPTIONS)
                                    .map((item) => {
                                        const isSelected =
                                            selected.filter((filter) => filter.key === item.key)
                                                .length > 0;
                                        return (
                                            <FilterItem
                                                key={item.key}
                                                filterKey={key}
                                                type={type}
                                                isSelected={isSelected}
                                                item={item}
                                                onChange={onChange}
                                                singleSelect={isSingleSelect(item)}
                                                filterSubmited={isfilterSubmited}
                                            />
                                        );
                                    })}
                                {filterOptions.length > MAX_OPTIONS && (
                                    <Row>
                                        <Col
                                            className="show-all"
                                            onClick={() => setShowAll(!showAll)}
                                        >
                                            {!showAll
                                                ? `Show All ${filterOptions.length}...`
                                                : 'Show Less'}
                                        </Col>
                                    </Row>
                                )}
                            </span>
                        ) : (
                            <FilterItem
                                key={`${key}_${type}`}
                                filterKey={key}
                                selected={selected}
                                options={filter_options}
                                type={type}
                                onChange={onChange}
                                onClear={onClear}
                                filterSubmited={isfilterSubmited}
                            />
                        )}
                    </Container>
                </Card.Body>
            </Collapse>
        </Card>
    );
};

export default Filter;
