import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { isPartner } from 'services/utils/user-utils';
import { useRoomStore } from 'store/room/reducer';
import styled from 'styled-components';

import { Canvas, Page } from 'components';
import { User } from 'services';
import { ModalData } from 'store/ui/types';
import { RoomState } from 'types/styling';
import { Client } from 'types/user';
import { CustomerDetails, Header } from './components';
import { Boards, Closet, Inspiration, Shop, Store } from './Tabs';
import { TRouteParams, TStylingRoomProps } from './types';

const Container = styled.div`
    --stylingRoomHeaderHeight: 90px;

    display: flex;
    flex-direction: column;
    max-height: 100vh;
`;

const Content = styled.div`
    display: flex;
    flex-direction: row;
    height: calc(100vh - var(--stylingRoomHeaderHeight));
`;

const TabContainer = styled.div`
    display: flex;
    flex-grow: 1;
`;

const StylingRoomComponent: FC<TStylingRoomProps> = ({
    type,
    user,
    styleRequest = {},
    loadStyleRequest,
    updateStyleRequest,
    clearCanvas,
    toggleModal,
    updateCanvasDataUrl,
    canvasLayout,
    revision,
    setCanvasLayout,
    setCanvasSizeIndex,
    addToCanvas,
    updateGender,
    clearSelectedFilters,
    location,
    clearError
}) => {
    const history = useHistory();
    const isDressingRoom = location.pathname.includes('dressing-room');
    const { request_uuid, tab } = useParams<TRouteParams>();
    const [showCustomerDetails, setShowCustomerDetails] = useState(false);
    const [tabComponent, setTabComponent] = useState<React.ReactNode>();
    const [client, setClient] = useState<Client>();
    const roomState = useRoomStore((store) => store.stylingType);
    const isPartnerStylist = isPartner();

    const fetchClientData = async (uuid: string) => {
        const { data } = await User.get(uuid);

        setClient(data);
    };

    const getStyleRequestType = () => {
        if (isDressingRoom || isPartnerStylist) {
            return RoomState.look;
        }
        return roomState === RoomState.moodboard ? RoomState.moodboard : RoomState.look;
    };

    const onSave = (dataUrl?: string) => {
        const MODAL_CONTENT = {
            look: 'SaveStyleboard',
            revision: 'SaveStyleboard',
            moodboard: 'SaveMoodboard',
            personal: 'SavePersonalShopping'
        };

        const styleRequestType = getStyleRequestType();
        let modalType;

        clearError();
        dataUrl && updateCanvasDataUrl(dataUrl);

        if (type && !isDressingRoom) {
            modalType = MODAL_CONTENT[canvasLayout === 'grid' ? RoomState.personal : type];
        } else if (!client) {
            modalType = MODAL_CONTENT[styleRequestType];
        } else {
            modalType =
                MODAL_CONTENT[canvasLayout === 'grid' ? RoomState.personal : styleRequestType];
        }

        toggleModal({
            type: modalType,
            url: isDressingRoom
                ? '/dressing-room/boards'
                : `/inbox?user_uuid=${
                      styleRequest.owner ? styleRequest.owner.user_uuid : user.user_uuid
                  }`
        } as ModalData);
    };

    const toggleCustomerDetails = (flag?: boolean) => {
        setShowCustomerDetails(flag === undefined ? !showCustomerDetails : flag);
    };

    useEffect(() => {
        !request_uuid && !isDressingRoom && history.push('/');
        !isDressingRoom && loadStyleRequest(request_uuid);

        setCanvasLayout('free');
        setCanvasSizeIndex(1);

        return () => {
            updateStyleRequest({});
            clearCanvas();
        };
    }, []);

    useEffect(() => {
        styleRequest.owner?.user_uuid && fetchClientData(styleRequest.owner.user_uuid);
    }, [styleRequest]);

    useEffect(() => {
        if (styleRequest.uuid || (isDressingRoom && user)) {
            updateGender(styleRequest.owner ? styleRequest.owner.gender : user.gender);
        }
    }, [styleRequest, user]);

    useEffect(() => {
        if (revision.items && type === RoomState.revision) {
            revision.items.forEach((item) => {
                if (!item.is_selected) addToCanvas(item);
            });
        }
    }, [revision, type]);

    useEffect(() => {
        switch (tab) {
            case 'boards':
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setTabComponent(<Boards />);
                break;
            case 'closet':
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setTabComponent(<Closet />);
                break;
            case 'inspiration':
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setTabComponent(<Inspiration />);
                break;
            case 'store':
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setTabComponent(<Store />);
                break;
            default:
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setTabComponent(<Shop />);
        }

        clearSelectedFilters();
    }, [tab]);

    return (
        <Page header={false} footer={false}>
            <Container>
                <Header
                    partnerType={user.partnerType}
                    customer={isDressingRoom ? user : styleRequest.owner}
                    styleData={styleRequest.styleData}
                    toggleCustomerDetailsFn={toggleCustomerDetails}
                    isDressingRoom={isDressingRoom}
                />
                <Content>
                    <TabContainer>{tabComponent}</TabContainer>
                    {!isDressingRoom && (
                        <CustomerDetails
                            customer={client}
                            stylist={styleRequest.stylist}
                            hideFn={() => toggleCustomerDetails(false)}
                            isVisible={showCustomerDetails}
                        />
                    )}
                    <Canvas
                        onSave={onSave}
                        type={isDressingRoom ? 'personal' : type}
                        isMoodboard={roomState === 'moodboard'}
                    />
                </Content>
            </Container>
        </Page>
    );
};

export const StylingRoom = StylingRoomComponent;
