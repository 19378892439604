import { Loader, ToggleButton } from 'components';
import content from 'content.json';
import React, { useState } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { Style, User } from 'services';
import { Stylist } from 'types/user';
import { CACHE_TIME_MS } from './consts';

const { inbox } = content;

interface Item {
    uuid: string;
    picture: string;
}
interface IBoards {
    uuid: string;
    stylist: Stylist;
}
const Boards: React.FC<IBoards> = ({ uuid, stylist }) => {
    const fetchStyleBoards = async () => {
        const res = await Style.getOutfits(uuid, stylist.user_uuid);
        return res?.data;
    };
    const fetchFavoritesBoards = async () => {
        const res = await User.favorites({ type: 'looks', uuid, params: {} });
        return res?.data;
    };

    const { data: styles, isFetching: isFetchingStyles } = useQuery<{
        items: Item[];
        quota_max: string;
    }>(['outfits-boards', uuid, stylist.user_uuid], fetchStyleBoards, { staleTime: CACHE_TIME_MS });
    const { data: favorites, isFetching: isFetchingFavorites } = useQuery<{
        items: Item[];
        quota_max: string;
    }>(['favorites-boards', uuid], fetchFavoritesBoards, { staleTime: CACHE_TIME_MS });

    const [section, setSection] = useState(inbox.bookings.info.boardsToggle[0].value);

    return (
        <div className="boards">
            {(isFetchingFavorites || isFetchingStyles) && <Loader />}
            <Row>
                <Col>
                    <ToggleButton
                        toggles={inbox.bookings.info.boardsToggle}
                        onChange={setSection}
                    />
                </Col>
            </Row>
            <Row>
                {section === 'styles' &&
                    styles?.items?.map((board) => (
                        <Col key={board.uuid}>
                            <Image src={board.picture} />
                        </Col>
                    ))}
                {section === 'favorites' &&
                    favorites?.items?.map((board) => (
                        <Col key={board.uuid}>
                            <Image src={board.picture} />
                        </Col>
                    ))}
                {section === 'styles' && !styles?.items?.length && <span>No previous boards</span>}
                {section === 'favorites' && !favorites?.items?.length && (
                    <span>No fvorite boards</span>
                )}
            </Row>
        </div>
    );
};
export default Boards;
