import 'react-international-phone/style.css';
import '../style.scss';

import { useEffect, useState } from 'react';
import { PhoneInput } from 'react-international-phone';
import { registerProperties } from 'services/mixpanel';

const phoneRegex = /^[+][0-9]*$/;

const Phone = ({ onChange, number = '', country = 'us' }) => {
    const [phone, setPhone] = useState('');
    useEffect(() => {
        setPhone(number);
    }, [number]);

    const isValid = (value) => value === '' || phoneRegex.test(value);

    return (
        <PhoneInput
            defaultCountry={country}
            value={phone}
            onChange={(phone, meta) => {
                if (isValid(phone)) {
                    setPhone(phone);
                    onChange(phone);
                    registerProperties({ Country: meta.country.iso2 });
                }
            }}
        />
    );
};

export default Phone;
