import './style.scss';

import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Image } from 'react-bootstrap';
import { getItemAvailability } from 'services/utils/availability-utils';

import content from 'content.json';
import { Formatter } from '../../services';
import { ClosetItem, Item } from '../../types/styling';

const { styling } = content;

interface IGridItem {
    item: Item | ClosetItem;
    id?: string;
    type: string;
    onClick?: () => void | null;
    onDetailsClick?: () => void;
    onImageClick?: () => void;
    onFavorite?: () => void;
    onRemove?: () => void;
    size: string;
    children: any;
    availability?: { [index: string]: { [size: string]: string } };
    index?: number;
}

const GridItem: React.FC<IGridItem> = ({
    item,
    id = '',
    type,
    onClick,
    onDetailsClick,
    onImageClick,
    onFavorite,
    onRemove,
    size = 'small',
    children,
    availability = {}
}) => {
    const showPrice = () => item.price && Number(item.price) > 0;
    item = item?.items ? item.items[0] : item;
    const isStockCheckRequired = type === 'shop' && Object.keys(availability).length > 0;
    const createSizesArray = () => {
        const iconType = getItemAvailability(availability, id);
        if (iconType && iconType[0].size !== 'init') {
            const sizesArray =
                item?.size &&
                Array.isArray(item?.size) &&
                item?.size.reduce((array: string[], size: string) => {
                    const sizeObj = iconType[iconType.findIndex((item) => item.size == size)];
                    if (sizeObj && sizeObj['icon'] !== 'notInStock') {
                        array.push(size);
                    }
                    return array;
                }, []);
            if (sizesArray) {
                if (Array.isArray(sizesArray) && sizesArray.length > 1) {
                    return sizesArray
                        ? sizesArray.join(', ')
                        : item.size
                        ? item.size && Array.isArray(item?.size) && item.size.join(',')
                        : styling.noSizes;
                } else if (Array.isArray(sizesArray) && sizesArray.length === 1) return sizesArray;
                else return 'Item out of stock';
            }
        }
    };
    const sizeArray = isStockCheckRequired
        ? createSizesArray()
        : item.size && Array.isArray(item?.size) && item.size.join(',');
    return (
        <div className={`grid-item ${size} ${type}-item`}>
            {type === 'shop' && (
                <div style={{ display: 'contents' }}>
                    <div
                        className={`grid-item small fav-star ${item.favorite ? 'filled' : 'empty'}`}
                        onClick={onFavorite}
                    />
                </div>
            )}
            {type === 'store' && <div className="remove" onClick={onRemove} />}
            {type === 'profile' && (
                <FontAwesomeIcon className="trash" icon={faTrashAlt} onClick={onRemove} />
            )}
            <Image
                src={item.pictureUrl || item.photo_large || item.picture}
                onClick={onImageClick ? onImageClick : onClick}
            />
            <div className="details">
                <span onClick={onDetailsClick ? onDetailsClick : onClick}>
                    {isStockCheckRequired ? (
                        <p
                            className={`sizes ${
                                sizeArray ? '' : 'grid-item small stockIcon loading'
                            } `}>
                            {sizeArray}
                        </p>
                    ) : (
                        type === 'shop' && <p className="sizes">{sizeArray}</p>
                    )}
                    <p className="title">{item.itemBrand || item.brand_name}</p>
                    <p className="price">
                        {type === 'shop' &&
                            showPrice() &&
                            Formatter.price(item.price, undefined, item?.currency)}
                    </p>
                </span>
                {(item.buyUrlShort || item.buy_url_short) &&
                    type === 'shop' &&
                    item.type !== 'inspiration' && (
                        <a href={item.productUrl || item.buy_url} target="_blank" rel="noreferrer">
                            {item.brand || item.buyUrlShort || item.buy_url_short}
                        </a>
                    )}
            </div>
            {children}
        </div>
    );
};
export default GridItem;
