import mixpanel from 'mixpanel-browser';
import Config from 'services/Config';
import { Stylist } from 'types/user';
import { BodyType, Style } from 'types/utils';

const detectDeviceType = (width: number) => {
    if (width > 992) return 'desktop';
    else if (width < 992 && width > 567) return 'tablet';
    else return 'mobile';
};

const mixpanelToken = Config.get('mixpanelToken');

export const initMixpanel = () => {
    if (mixpanelToken) {
        mixpanel.init(mixpanelToken, {
            debug: false
        });
        mixpanel.register({
            Platform: 'web',
            Device: detectDeviceType(window.innerWidth)
        });
        mixpanel.track('Website Visits', {});
    }
};

export const trackEvent = (eventDetails: { name: string; properties: Record<string, unknown> }) =>
    mixpanel.track(eventDetails.name, eventDetails.properties);

export const registerProperties = (propertiesDetails: {
    [key: string]: string | Style[] | Record<string, BodyType[]>;
}) => mixpanel.register(propertiesDetails);

export const isMatchedStylist = (stylistsList: Stylist[], stylistUUID: string | undefined) => {
    if (stylistUUID) {
        const matchedStylist = stylistsList.find((stylist) => stylist.uuid == stylistUUID);
        if (matchedStylist) return true;
        else return false;
    }
    return false;
};
