import './theme-vars.scss';
import './style.scss';

import PrivateRoute from 'components/PrivateRoute';
import { useWithDispatch } from 'hooks';
import { useInitCommonReducer } from 'hooks/app';
import { useLDClient, withLDProvider } from 'launchdarkly-react-client-sdk';
import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { ICredentials } from 'services/auth-service';
import { initalizedTwilio } from 'store/services/actions';
import { useServicesStore } from 'store/services/reducer';
import { login, logout } from 'store/user/actions';
import { useUserStore } from 'store/user/reducer';
import { Client } from 'types/user';

import Routes from './routes';
import { Config, Cookies } from './services';
import { extractUserData } from './services/utils/user-utils';
import * as Views from './views';

const cookie = Cookies.get('user');
const ngUser = localStorage.getItem('user') ?? '';

const App: React.FC = () => {
    const [path, setPath] = useState<string | null>(null);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const location = useLocation();
    const user = useUserStore((store) => store?.user);
    const isTwilioInitialized = useServicesStore((store) => store?.isTwilioInitialized);
    const loginAction = useWithDispatch(login);
    const logoutAction = useWithDispatch(logout);
    const initalizedTwilioAction = useWithDispatch(initalizedTwilio);
    useInitCommonReducer();
    const haveStoredUserDetails = !user && ((cookie && cookie.token) || ngUser);
    const ldClient = useLDClient();

    const loginWithToken = async (token: Partial<ICredentials>) => {
        const loggedInUser = await loginAction(token);
        if (loggedInUser) {
            const userDetails = extractUserData(loggedInUser as Client);
            if (ldClient && userDetails) {
                await ldClient.identify({
                    kind: 'user',
                    ...userDetails
                });
                setIsLoaded(true);
            }
        } else setIsLoaded(true);
    };

    useEffect(() => {
        if (!user && cookie && !cookie.token && !ngUser) {
            logoutAction();
            setIsLoaded(true);
        }
    }, []);

    useEffect(() => {
        if (haveStoredUserDetails) {
            const token = cookie.token ? cookie.token : JSON.parse(ngUser)?.token;
            loginWithToken({ token });
        }
    }, [ldClient]);

    useEffect(() => {
        if (user && !isTwilioInitialized) initalizedTwilioAction();
    }, [user]);

    useEffect(() => {
        if (location.pathname !== path) {
            window.scrollTo(0, 0);
            setPath(location.pathname);
        }
    }, [location]);

    return (
        <div className="app">
            {isLoaded && (
                <Switch>
                    <Route exact path="/" component={Views.Landing} />
                    <Route exact path="/terms" component={Views.Terms} />
                    <Route exact path="/privacy" component={Views.Privacy} />
                    <Route exact path="/item/:item_uuid" component={Views.Item} />
                    <PrivateRoute exact path="/inbox" component={Routes.Inbox} />
                    <PrivateRoute
                        path="/styling-room/:request_uuid/:tab/:subTab?"
                        component={Views.StylingRoom}
                    />
                    <PrivateRoute
                        path="/dressing-room/:tab/:subTab?"
                        component={Views.StylingRoom}
                    />
                    <PrivateRoute path="/profile/:tab?" component={Views.Profile} />
                    <PrivateRoute path="/settings/:tab?" component={Views.Settings} />
                    <Route>
                        <Redirect to="/" />
                    </Route>
                </Switch>
            )}
        </div>
    );
};

export default withLDProvider({
    clientSideID: Config.get('launchDarklyClientSideId') ?? ''
})(App);
