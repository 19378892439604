import {
    faFacebookF,
    faInstagram,
    faLinkedin,
    faPinterestP,
    IconDefinition
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { ProfilePhoto } from 'components';
import content from 'content.json';
import { StyleData } from 'types/styling';
import { GeneralUser, TUserPartnerType } from 'types/user';
import { TRouteParams } from '../types';
import { parseCoreUser } from 'services/utils/user-utils';

const { styling } = content;

const HeaderContainer = styled.div`
    height: var(--stylingRoomHeaderHeight);
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--themeBorderColor);
    background: #fff;
    font-family: UntitledSans-Light, sans-serif;
    font-size: 14px;
`;
const BackButton = styled(Link)`
    position: static;
    margin-left: 25px;
    padding: 10px 20px;
    color: #000000;

    &:hover {
        color: #000000;
    }
`;

const CustomerDetails = styled.div`
    display: flex;
    align-items: center;
    min-width: 245px;
    margin-left: 5px;
`;

const CustomerProfilePhoto = styled(ProfilePhoto)`
    width: 48px;
    height: 48px;
    font-size: 16px;
    flex: none;
`;

const CustomerName = styled.div`
    font-family: UntitledSans-Medium, sans-serif;
    margin-left: 15px;
    text-transform: capitalize;
    white-space: nowrap;
    max-width: 180px;
    text-overflow: ellipsis;
    overflow: hidden;
`;

const CustomerSocialLinks = styled.div`
    display: flex;

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #000000;
        width: 18px;
        height: 18px;
        border-radius: 10px;
        font-size: 12px;
        color: #f4f4f4;
        margin-left: 4px;
    }
`;

const Nav = styled.nav<{ activeTab: string }>`
    display: flex;
    align-items: center;
    margin-left: 15px;

    .link-to-${(p) => p.activeTab} {
        font-weight: bold;
        cursor: default;
    }
`;

const NavLink = styled(Link)`
    margin-right: 20px;
    color: #000000;
    font-family: UntitledSans-Regular, sans-serif;

    &:hover {
        font-weight: bold;
        color: #000000;
    }
`;

const DetailsButton = styled.button`
    background: transparent;
    border: none;
    color: #000000;
    font-family: UntitledSans-Regular, sans-serif;
    margin-left: auto;
    margin-right: 10px;
    padding: 10px 20px;

    &:hover {
        font-weight: bold;
        color: #000000;
    }
`;

type TProps = {
    partnerType: TUserPartnerType;
    customer?: GeneralUser;
    styleData?: StyleData;
    isDressingRoom: boolean;
    toggleCustomerDetailsFn: (t?: boolean) => void;
};

type TSocialLink = {
    href: string;
    icon: IconDefinition;
};

export const Header: FC<TProps> = ({
    partnerType,
    customer,
    styleData,
    isDressingRoom = false,
    toggleCustomerDetailsFn
}) => {
    const isValidUrl = (value: string) => {
        try {
            return new URL(value).protocol === 'https:';
        } catch (e) {
            return false;
        }
    };

    const sanitizePinterestUsername = (username: string) =>
        (username ?? '')?.replace('/[^a-z0-9_]/gi', '');

    const toPinterestUrl = (value: string) => {
        return isValidUrl(value)
            ? value
            : `http://pinterest.com/${sanitizePinterestUsername(value)}`;
    };

    const { tab, subTab } = useParams<TRouteParams>();
    const { firstName, fullName } = useMemo(
        () => parseCoreUser(customer as GeneralUser),
        [customer]
    );
    const customerSocialLinks = useMemo<TSocialLink[]>(() => {
        if (styleData) {
            const { instagram_username, facebook_username, linkedin_username, pinterest_username } =
                styleData;

            return [
                instagram_username && {
                    href: `http://instagram.com/${instagram_username}`,
                    icon: faInstagram
                },
                facebook_username && {
                    href: `http://facebook.com/${facebook_username}`,
                    icon: faFacebookF
                },
                linkedin_username && {
                    href: `http://linkedin.com/${linkedin_username}`,
                    icon: faLinkedin
                },
                pinterest_username && {
                    href: toPinterestUrl(pinterest_username),
                    icon: faPinterestP
                }
            ].filter(Boolean) as TSocialLink[];
        } else {
            return [];
        }
    }, [styleData]);

    const constructNavLink = (route: string) => (subTab ? '../' + route : route);

    return (
        <HeaderContainer>
            <BackButton className="back-btn" to="/inbox" />
            <CustomerDetails>
                {customer && (
                    <>
                        <CustomerProfilePhoto user={customer} level={true} />
                        <CustomerName>{fullName}</CustomerName>
                        <CustomerSocialLinks>
                            {customerSocialLinks.map((link, index) => (
                                <a key={index} href={link.href} target="_blank" rel="noreferrer">
                                    <FontAwesomeIcon icon={link.icon} />
                                </a>
                            ))}
                        </CustomerSocialLinks>
                    </>
                )}
            </CustomerDetails>
            <Nav activeTab={tab}>
                {customer && (
                    <NavLink
                        to={constructNavLink(
                            `closet/${partnerType === 'WishiB2B' ? 'cart' : 'closet'}`
                        )}
                        className="link-to-closet">
                        {isDressingRoom
                            ? styling.header.mycloset
                            : styling.header.closet.replace('%client%', firstName)}
                    </NavLink>
                )}
                <NavLink to={constructNavLink('shop')} className="link-to-shop">
                    {styling.header.shop}
                </NavLink>
                <NavLink to={constructNavLink('store')} className="link-to-store">
                    {styling.header.store}
                </NavLink>
                <NavLink to={constructNavLink('inspiration')} className="link-to-inspiration">
                    {styling.header.inspiration}
                </NavLink>
                <NavLink to={constructNavLink('boards')} className="link-to-boards">
                    {styling.header.boards}
                </NavLink>
            </Nav>
            {!isDressingRoom && (
                <DetailsButton onClick={() => toggleCustomerDetailsFn()}>
                    {styling.header.details}
                </DetailsButton>
            )}
        </HeaderContainer>
    );
};
