/* eslint-disable no-useless-escape */
import moment from 'moment';

const Formatter = {
    price: (price, fractionDigits = 0, currency = 'USD') => {
        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency,
            minimumFractionDigits: fractionDigits,
            maximumFractionDigits: fractionDigits
        });
        if (typeof price === 'string') {
            price = parseFloat(price.replaceAll(/[^\d.]/g, ''));
        }

        return formatter.format(price || 0);
    },
    name: (name) =>
        name
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' '),
    queryString: (params) =>
        params
            .substring(1)
            .split('&')
            .reduce((ac, a) => ({ ...ac, [a.split('=')[0]]: a.split('=')[1] }), {}),
    time: (timestamp, format = 'MMMM DD, YYYY') => {
        let date = moment(new Date(timestamp));
        if (!date.isValid()) date = moment.unix(timestamp);
        let formatted = date.fromNow();

        if (moment().diff(date, 'days') > 7) {
            formatted = date.format(format);
        }
        return formatted;
    },
    date: (timestamp) => {
        let date = moment(new Date(timestamp));
        if (!date.isValid()) date = moment.unix(timestamp);
        let formatted = date.format('MMM DD');

        const currentYear = new Date().getFullYear();

        if (currentYear > date.year()) {
            formatted = date.format('MMM DD, YYYY');
        }
        return formatted;
    },
    number: (number) => new Intl.NumberFormat().format(number),
    priceFormatter: (price) => {
        if (typeof price !== 'string') price = JSON.stringify(price);
        return price ? JSON.stringify(parseInt(price.replace(',', ''), 10)) : price;
    }
};

export default Formatter;
