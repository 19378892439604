import axios from 'axios';

import Config from './Config';
import { getUserToken } from './utils/token-utils';

const apiRoot = Config.get('api-gw');

const SessionManagment = {
    urls: {
        sessions: (uuid: string) =>
            `${apiRoot}session-management/stylists/${uuid}/sessions`,
        searchBooking: (uuid: string, channel_id: string) =>
            `${apiRoot}session-management/stylists/${uuid}/sessions/${channel_id}`,
        sessionCount: (uuid: string) =>
            `${apiRoot}session-management/stylists/${uuid}/sessions-count`
    },
    getSessionConfig: (token: string) =>
        axios.get(`${apiRoot}session-management/config`, {
            headers: { token: getUserToken() ?? token }
        }),
    getSessionCount: (uuid: string, token: string, sessionfilters: string[]) =>
        axios.get(`${apiRoot}session-management/stylists/${uuid}/sessions-count`, {
            headers: { token: getUserToken() ?? token },
            params: { filters: sessionfilters }
        }),
    getSessions: (uuid: string, token: string) =>
        axios.get(`${apiRoot}session-management/stylists/${uuid}/sessions`, {
            headers: { token: getUserToken() ?? token }
        }),
    getSingleBooking: (uuid: string, channel_id: string, token: string) =>
        axios.get(
            `${apiRoot}session-management/stylists/${uuid}/sessions/${channel_id}`,
            {
                headers: { token: getUserToken() ?? token }
            }
        )
};

export default SessionManagment;
