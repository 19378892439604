import axios from 'axios';

import Config from './Config';
import { getUserToken } from './utils/token-utils';

const apiRoot = Config.get('api-gw');

const Style = {
    geMatches: (user_id) =>
        axios.get(`${apiRoot}proxy/user/${user_id}/bestMatchesNew`, {
            headers: { token: getUserToken() }
        }),
    getOutfit: (outfit_uuid, uuid = 'null') =>
        axios.get(`${apiRoot}proxy/outfit/web/${outfit_uuid}/${uuid}`, {
            headers: { token: getUserToken() }
        }),
    getOutfits: (user_uuid, stylist_uuid) =>
        axios.get(`${apiRoot}proxy/outfit/list/web/paging/${user_uuid}/${stylist_uuid}`, {
            params: { outfits: 1, from: 1, count: 50 },
            headers: { token: getUserToken() }
        }),
    getItem: (item_uuid, user_uuid) =>
        axios.get(`${apiRoot}proxy/item/catalog/${item_uuid}`, {
            params: { user_uuid },
            headers: { token: getUserToken() }
        }),
    filters: () =>
        axios.get(`${apiRoot}proxy/giftItem/filters?client=web`, {
            headers: { token: getUserToken() }
        }),
    getRequest: (request_uuid) =>
        axios.get(`${apiRoot}proxy/styleRequest/retrieve/${request_uuid}`, {
            headers: { token: getUserToken() }
        }),
    tags: (gender, type) =>
        axios.get(`${apiRoot}proxy/tag/active`, {
            params: { gender, type },
            headers: { token: getUserToken() }
        }),
};

export default Style;
