import './style.scss';

import { useEffect, useRef, useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import content from 'content.json';

const { styling } = content;

const CanvasToolbarButton = ({ type, placement = 'top', onClick }) => (
    <OverlayTrigger
        placement={placement}
        overlay={
            <Tooltip
                placement={placement}
                id="canvas-toolbar-tooltip"
                className="canvas-toolbar-tooltip"
            >
                {styling.canvas.toolbar[type]}
            </Tooltip>
        }
    >
        <Button className={`${type}`} onClick={onClick} />
    </OverlayTrigger>
);

const CanvasToolbar = ({
    top,
    left,
    width,
    clearBackground = false,
    disabled = false,
    onClick
}) => {
    const [placement, setPlacement] = useState('top');
    const ref = useRef();

    useEffect(() => {
        document.addEventListener('keydown', handleKeypress);
        return () => {
            document.removeEventListener('keydown', handleKeypress);
        };
    }, []);

    const handleKeypress = (e) => {
        if (e.keyCode === 8) onClick('remove');
    };

    const checkPlacement = () => {
        setPlacement(ref.current.getBoundingClientRect().top > 45 ? 'top' : 'bottom');
    };

    return (
        <div
            className={`toolbar ${disabled ? 'disabled' : ''}`}
            style={{
                top: `${top}px`,
                left: `${left}px`,
                width: `${width}px`
            }}
            ref={ref}
            onMouseEnter={checkPlacement}
        >
            <CanvasToolbarButton
                placement={placement}
                type="remove"
                onClick={() => onClick('remove')}
            />
            <CanvasToolbarButton
                placement={placement}
                type="flip"
                onClick={() => onClick('flip')}
            />
            <CanvasToolbarButton
                placement={placement}
                type="flop"
                onClick={() => onClick('flop')}
            />
            <CanvasToolbarButton
                placement={placement}
                type="crop"
                onClick={() => onClick('crop')}
            />
            <CanvasToolbarButton
                placement={placement}
                type="backward"
                onClick={() => onClick('backward')}
            />
            <CanvasToolbarButton
                placement={placement}
                type="forward"
                onClick={() => onClick('forward')}
            />
            {clearBackground ? (
                <CanvasToolbarButton
                    placement={placement}
                    type="undo-remove-background"
                    onClick={() => onClick('undo-remove-background')}
                />
            ) : (
                <CanvasToolbarButton
                    placement={placement}
                    type="remove-background"
                    onClick={() => onClick('remove-background')}
                />
            )}
        </div>
    );
};

export default CanvasToolbar;
