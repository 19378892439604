import '../style.scss';

import content from 'content.json';
import React from 'react';
import { Col, Dropdown, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { isPartner } from 'services/utils/user-utils';
import { useBookingStore } from 'store/booking/reducer';
import { QueueType } from 'types/booking';

const { inbox } = content;

interface IBookingsQuickFilters {
    onOpen: (isOpen: boolean) => void;
    onMenu: (menuItem: QueueType) => void;
    showNewMessages: () => void;
    isNewMessages: boolean;
}
const QuickFilters: React.FC<IBookingsQuickFilters> = ({
    onOpen,
    onMenu,
    showNewMessages,
    isNewMessages
}) => {
    const unreadMessages = useBookingStore((store) => store.unreadMessages);
    const sizeClass =
        unreadMessages > 9 && unreadMessages <= 99 ? 'medium' : unreadMessages > 99 ? 'large' : '';
    const isPartnerStylist = isPartner();

    return (
        <Row className="quick-filters">
            <Col>
                <OverlayTrigger
                    placement="bottom"
                    overlay={
                        <Tooltip id="unread-messages-toltip">
                            {inbox.bookings.tooltips.unread}
                        </Tooltip>
                    }
                >
                    <div className={`new-messages ${isNewMessages ? 'clicked' : ''}`}>
                        <div
                            data-test-id="new-messages"
                            className="inbox"
                            onClick={showNewMessages}
                        ></div>
                        {unreadMessages > 0 && (
                            <div className={`messages ${sizeClass}`}>
                                <p>{unreadMessages}</p>
                            </div>
                        )}
                    </div>
                </OverlayTrigger>
            </Col>
            <Col>
                <Dropdown drop="down" className="more-dropdown">
                    <Dropdown.Toggle id="booking-dropdown" variant="light" className="more-btn">
                        <OverlayTrigger
                            placement="bottom"
                            overlay={
                                <Tooltip id="more-toltip">
                                    {!isPartnerStylist
                                        ? inbox.bookings.tooltips.more
                                        : inbox.bookings.tooltips.archived}
                                </Tooltip>
                            }
                        >
                            <div
                                data-test-id="view-more"
                                className="side-menu"
                                onClick={() => onOpen(false)}
                            />
                        </OverlayTrigger>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {!isPartnerStylist && (
                            <Dropdown.Item
                                data-test-id={QueueType.inquiries}
                                onClick={() => onMenu(QueueType.inquiries)}
                            >
                                {QueueType.inquiries}
                            </Dropdown.Item>
                        )}
                        <Dropdown.Item
                            data-test-id={QueueType.archived}
                            onClick={() => onMenu(QueueType.archived)}
                        >
                            {QueueType.archived}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Col>
        </Row>
    );
};

export default QuickFilters;
