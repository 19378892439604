import content from 'content.json';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import ClientInfo from 'containers/ClientInfo';
import { Client, Stylist } from 'types/user';
import { TRouteParams } from '../types';

const { inbox } = content;

const Container = styled.div<{ isVisible: boolean }>`
    flex: none;
    overflow-y: hidden;
    max-width: ${({ isVisible }) => (isVisible ? 401 : 0)}px;
    border-left: ${({ isVisible }) => (isVisible ? 1 : 0)}px solid var(--themeBorderColor);
    transition: max-width 300ms ease-out;

    .client-info {
        padding: 0;

        .tab-content {
            height: calc(100vh - 235px);
            overflow: auto;

            .tab-pane {
                margin: 0 20px;
            }
        }
    }
`;

const Header = styled.div`
    min-width: 400px;
    display: flex;
    align-items: center;
    padding: 30px 30px 30px 15px;
    border-bottom: 1px solid var(--themeBorderColor);
`;

const Title = styled.div`
    font-weight: bold;
`;

const CloseButton = styled.button`
    background: transparent url(/assets/icons/x.png) center / 18px no-repeat;
    border: none;
    width: 30px;
    height: 30px;
    margin-left: auto;
`;

type TProps = {
    customer?: Client;
    stylist?: Stylist;
    hideFn: () => void;
    isVisible: boolean;
};

export const CustomerDetails: FC<TProps> = ({ customer, stylist, hideFn, isVisible }) => {
    const { request_uuid } = useParams<TRouteParams>();

    return (
        <Container isVisible={isVisible}>
            <Header>
                <Title>{inbox.bookings.details}</Title>
                <CloseButton onClick={() => hideFn()} />
            </Header>
            {stylist && customer && isVisible && (
                <ClientInfo client={{ ...customer, request_uuid }} stylist={stylist} />
            )}
        </Container>
    );
};
