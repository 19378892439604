import { Loader } from 'components';
import content from 'content.json';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { Formatter } from 'services';
import { User } from 'services';

import { Note } from './types';
import { CACHE_TIME_MS } from './consts';

const { inbox } = content;

const Notes: React.FC<{ uuid: string; stylist: any }> = ({ stylist, uuid }) => {
    const fetchNotes = async () => {
        const res = await User.notes(uuid, { stylist_uuid: stylist.stylist_uuid });
        return res?.data;
    };

    const { data, isLoading, isFetching } = useQuery<{ items: Note[]; quota_max: string }>(
        ['user-notes', uuid, stylist.stylist_uuid],
        fetchNotes,
        { staleTime: CACHE_TIME_MS }
    );
    const notes = data?.items ?? [];
    return (
        <div className="notes">
            {(isLoading || isFetching) && <Loader />}
            {notes.length === 0 && <span>No notes</span>}
            {notes.map((note, index) => (
                <Container className="note" fluid key={index}>
                    <Row className="note-header">
                        <Col>
                            <p className="name">
                                {note.stylist.uuid === stylist?.user_uuid
                                    ? inbox.bookings.info.you
                                    : note.stylist.name}
                            </p>
                            <p className="time">
                                {Formatter.time(new Date(note.created).getTime())}
                            </p>
                        </Col>
                    </Row>
                    <Row className="note-content">
                        <Col>{note.note}</Col>
                    </Row>
                </Container>
            ))}
        </div>
    );
};
export default Notes;
