import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';

import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { initMixpanel } from 'services/mixpanel';

import App from './App';
import SentryLogging from './services/SentryLogging';
import { store } from './store';

const queryClient = new QueryClient();

SentryLogging();
initMixpanel();

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    <Provider store={store}>
        <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <Router>
                <App />
            </Router>
        </QueryClientProvider>
    </Provider>
);
