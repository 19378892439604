import { Loader } from 'components';
import content from 'content.json';
import React from 'react';
import { Col, Container, Image, ListGroup, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { User } from 'services';
import { UserStyles } from 'types/styles/user';
import { Client } from 'types/user';

import { CACHE_TIME_MS, STYLE_VALUES } from './consts';
import { getBudgetView } from './utils';

const { inbox: Inbox } = content;
interface IInfo {
    client: Client;
    uuid: string;
}
const Info: React.FC<IInfo> = ({ uuid, client }) => {
    const inbox = Inbox as Record<string, any>;

    const fetchStyles = async () => {
        if (client.request_uuid) {
            const res = await User.styleData(uuid, { session_uuid: client.request_uuid });
            return res?.data;
        }
    };
    const { data: style, isFetching } = useQuery<UserStyles>(
        ['style-data', uuid, client.request_uuid],
        fetchStyles,
        { staleTime: CACHE_TIME_MS }
    );

    const renderStyleItems = () =>
        STYLE_VALUES.map((val, index) => getStyleItem(val as keyof typeof style, index)).filter(
            (t) => t
        );

    const getStyleItem = (name: keyof typeof style, index: number) => {
        // TODO: this is a bad code, need to refactor using the utils file.
        let item;
        let bodyIssuesSection: Record<string, string> = {};
        const styleItem = style && (style[name] as any);

        if (Array.isArray(styleItem)) {
            item = styleItem
                .map((item: any) => {
                    let value = typeof item === 'string' ? item : item.value;

                    if (name === 'colors_and_prints_objects') {
                        value = item.picture ? item.picture : item.key;
                    }

                    if (!value) {
                        if (item.name) value = item.name;
                        if (item.category_name)
                            value = (
                                <span>
                                    {item.category_name}: <b>{item.size}</b>
                                </span>
                            );
                    }
                    return value;
                })
                .join(', ');

            if (name === 'body_tags' && style?.height)
                item += `${item.length ? ', ' : ''}${style?.height?.value}`;

            if (
                name === 'style_icons' &&
                style?.style_icons_comments &&
                style?.style_icons_comments?.length
            )
                item += `${item.length ? ', ' : ''}${style.style_icons_comments}`;

            if (
                name === 'holiday_type' &&
                style?.holiday_other_text &&
                style?.holiday_other_text?.length
            )
                item += `${item.length ? ', ' : ''}${style?.holiday_other_text}`;

            if (name === 'goals_new' && style?.goal_other_text && style?.goal_other_text.length)
                item += `${item.length ? ' - ' : ''}${style.goal_other_text}`;

            if (name === 'events' && style?.event_other_text && style?.event_other_text.length)
                item += `${item.length ? ' - ' : ''}${style.event_other_text}`;
            if (
                name === 'what_matters' &&
                style?.what_matters_comments &&
                style?.what_matters_comments.length
            )
                item += `${item.length ? ', ' : ''}${style.what_matters_comments}`;

            if (name === 'colors_and_prints_objects' && item) {
                item = !inbox.bookings.info.colors[item] ? (
                    <>
                        <span>Preferred colors:</span>
                        &nbsp;
                        {styleItem.map(({ key, picture, name }) => (
                            <Image key={key} src={picture} title={name} />
                        ))}
                    </>
                ) : (
                    inbox.bookings.info.colors[item]
                );
            }

            if (name === 'colors_to_avoid') {
                item = (
                    <>
                        <span>
                            <b>Avoid</b> colors:
                        </span>
                        &nbsp;
                        {styleItem.length
                            ? styleItem.map(({ key, picture, name }) => (
                                  <Image key={key} src={picture} title={name} />
                              ))
                            : 'No colors selected'}
                        {style?.colors_to_avoid_comment && (
                            <div>Comment: {style.colors_to_avoid_comment}</div>
                        )}
                    </>
                );
            }

            if (name === 'patterns_to_avoid') {
                item = (
                    <>
                        <span>
                            <b>Avoid</b> patterns:
                        </span>
                        &nbsp;
                        {styleItem.length
                            ? styleItem.map(({ key, picture, name }) => (
                                  <Image key={key} src={picture} title={name} />
                              ))
                            : 'No patterns selected'}
                    </>
                );
            }

            if (name === 'necklines_to_avoid') {
                item = (
                    <>
                        <span>
                            <b>Avoid</b> necklines:
                        </span>
                        &nbsp;
                        <span>{styleItem.join(', ')}</span>
                    </>
                );
            }

            if (name === 'budget_ranges') item = getBudgetView(style);

            if (name === 'body_issues') {
                let sections = ['body_issues_other', 'body_issues_details'];
                if (style) {
                    sections = sections.filter((item) => !!style[item as keyof UserStyles]);
                    sections.forEach((item) => {
                        const prefix = inbox.bookings.info.prefixes[item];
                        bodyIssuesSection = {
                            ...bodyIssuesSection,
                            [prefix]: style[item as keyof UserStyles]
                        };
                    });
                }
            }
        } else {
            item = style?.[name]
                ? (style[name] as any)?.value
                    ? (style[name] as any)?.value
                    : style[name]
                : '';

            if (name === 'gender') {
                item = [
                    style?.gender,
                    style?.age || client.age_partners || client.age,
                    style?.zodiac_sign
                ]
                    .filter((s) => s)
                    .join(', ');
            }

            if (name === 'dress_size') {
                let items = [
                    'dress_size',
                    'bottom_size',
                    'shoes_size',
                    'top_size',
                    'jeans_size',
                    'size_outerwear',
                    'size_one_piece',
                    'size_underwear'
                ];
                items = items.filter((item) => !!style?.[item as keyof typeof style]);
                const sizes = items.map((item, index) => (
                    <span key={item}>
                        {inbox.bookings.info.sizes[item]}:{' '}
                        <b>{String(style?.[item as keyof typeof style])}</b>
                        {index < items.length - 1 ? ', ' : ''}
                    </span>
                ));
                item = sizes.join('').length ? sizes : '';
            }

            if (name === 'work_type' && style?.work_comments?.length)
                item += `${item.length ? ', ' : ''}${style.work_comments}`;

            if (name === 'style_icons' && style?.style_icons_comments.length)
                item += `${item.length ? ', ' : ''}${style?.style_icons_comments}`;

            if (name === 'heels') {
                if (style?.heels_type?.length) {
                    const types = style?.heels_type.map((x) => x.value).join(', ');
                    item += `${item.length ? ', ' : ''}${types}`;
                }
                if (style?.heels_type_comments) {
                    item += `${item.length ? ', ' : ''}${style?.heels_type_comments}`;
                }
            }

            if (name === 'occupation' && styleItem) {
                item = (
                    <>
                        <span>Occupation:</span>
                        &nbsp;
                        <b>{styleItem}</b>
                    </>
                );
            }
        }

        if (!item) {
            return;
        }

        const icon = inbox.icons[name] ? <Image src={inbox.icons[name]} /> : <></>;

        const bodyIssesInfo = () =>
            Object.keys(bodyIssuesSection).map((key, index) => (
                <div key={index}>
                    {key}: <b>{bodyIssuesSection[key]}</b>
                </div>
            ));

        return (
            <ListGroup.Item key={index}>
                <Container fluid>
                    <Row>
                        <Col xs={1}>{icon}</Col>
                        <Col>
                            {inbox.bookings.info.prefixes[name] ? (
                                <span>
                                    {inbox.bookings.info.prefixes[name]}: <b>{item}</b>
                                    {bodyIssesInfo()}
                                </span>
                            ) : (
                                item
                            )}
                        </Col>
                    </Row>
                </Container>
            </ListGroup.Item>
        );
    };

    return (
        <div className="photos">
            {isFetching && <Loader />}
            <Row>
                <Col>
                    <ListGroup>
                        {client.location && (
                            <ListGroup.Item>
                                <Container fluid>
                                    <Row>
                                        <Col xs={1}>
                                            <Image src={inbox.icons.location} />
                                        </Col>
                                        <Col>{client.location}</Col>
                                    </Row>
                                </Container>
                            </ListGroup.Item>
                        )}
                        {style && renderStyleItems()}
                    </ListGroup>
                </Col>
            </Row>
        </div>
    );
};
export default Info;
