import axios from 'axios';

import Config from './Config';
import { getUserToken } from './utils/token-utils';

const apiRoot = Config.get('api-gw');

const User = {
    urls: {
        inbox: ({ user_uuid }) => `${apiRoot}proxy/user/${user_uuid}/inbox`
    },
    get: (uuid, token) =>
        axios.get(`${apiRoot}proxy/user/${uuid}`, {
            headers: { token: token || getUserToken() }
        }),
    getItem: (item_uuid) =>
        axios.get(`${apiRoot}proxy/item/${item_uuid}`, {
            headers: { token: getUserToken() }
        }),
    update: (data) =>
        axios.put(`${apiRoot}proxy/user/${data.user_uuid}`, data, {
            headers: { token: getUserToken() }
        }),
    profilePicture: ({ uuid, token, data }) =>
        axios.post(`${apiRoot}proxy/user/user-profile-picture/${token}/${uuid}`, data, {
            headers: { token: getUserToken() }
        }),
    refresh: (uuid) =>
        axios.get(`${apiRoot}proxy/user/${uuid}`, { headers: { token: getUserToken() } }),
    favorites: ({ type = '', uuid, params }) =>
        type
            ? axios.get(`${apiRoot}proxy/user/${uuid}/favorites/${type}`, {
                  params,
                  headers: { token: getUserToken() }
              })
            : axios.get(`${apiRoot}proxy/user/${uuid}/favourites`, {
                  params,
                  headers: { token: getUserToken() }
              }),
    cart: ({ user_uuid, params }) =>
        axios.get(`${apiRoot}proxy/user/${user_uuid}/cart`, {
            params,
            headers: { token: getUserToken() }
        }),
    wishlist: ({ user_uuid, params }) =>
        axios.get(`${apiRoot}user/${user_uuid}/partnerWishlist`, {
            params,
            headers: { token: getUserToken() }
        }),
    closet: ({ user_uuid, params }) =>
        axios.get(`${apiRoot}proxy/user/closet/${user_uuid}/${user_uuid}`, {
            params,
            headers: { token: getUserToken() }
        }),
    orders: ({ user_uuid, params }) =>
        axios.get(`${apiRoot}proxy/user/${user_uuid}/orders`, {
            params,
            headers: { token: getUserToken() }
        }),
    ordersFilters: (user_uuid, params) =>
        axios.get(`${apiRoot}proxy/user/${user_uuid}/orders/filters`, {
            params,
            headers: { token: getUserToken() }
        }),
    inbox: ({ user_uuid }) =>
        axios.get(`${apiRoot}proxy/user/${user_uuid}/inbox`, {
            headers: { token: getUserToken() }
        }),
    styleData: (user_uuid, params) => {
        if (user_uuid) {
            return axios.get(`${apiRoot}proxy/user/${user_uuid}/styleData`, {
                params,
                headers: { token: getUserToken() }
            });
        }
    },
    style: (user_uuid) =>
        axios.get(`${apiRoot}proxy/user/${user_uuid}/style`, {
            headers: { token: getUserToken() }
        }),
    quizResponses: ({ user_uuid, token }) => {
        return axios.get(`${apiRoot}styleData/quizResponses`, {
            params: { userUuid: user_uuid },
            headers: { token }
        });
    },

    photos: ({ user_uuid, params }) => {
        if (user_uuid) {
            return axios.get(`${apiRoot}proxy/user/${user_uuid}/photos`, {
                params,
                headers: { token: getUserToken() }
            });
        }
    },
    photo: ({ user_uuid, data, token }) => {
        if (user_uuid) {
            return axios.post(`${apiRoot}proxy/user/${user_uuid}/photos`, data, {
                headers: { token }
            });
        }
    },
    looks: ({ user_uuid, params }) =>
        axios.get(`${apiRoot}proxy/user/${user_uuid}/looks`, {
            params,
            headers: { token: getUserToken() }
        }),
    notes: (user_uuid, params) =>
        axios.get(`${apiRoot}proxy/client/${user_uuid}/notes`, {
            params,
            headers: { token: getUserToken() }
        }),
    closetFilters: ({ user_uuid, params }) =>
        axios.get(`${apiRoot}proxy/user/${user_uuid}/closet/filters`, {
            params,
            headers: { token: getUserToken() }
        }),
    looksFilters: ({ user_uuid, params }) =>
        axios.get(`${apiRoot}proxy/user/${user_uuid}/looks/filters`, {
            params,
            headers: { token: getUserToken() }
        }),
    cartFilters: (user_uuid, params) =>
        axios.get(`${apiRoot}proxy/user/${user_uuid}/cart/filters`, {
            params,
            headers: { token: getUserToken() }
        }),
    removeItem: (item_uuid) =>
        axios.delete(`${apiRoot}proxy/item/${item_uuid}`, {
            headers: { token: getUserToken() }
        }),
    removeLook: ({ user_uuid, look_uuid }) =>
        axios.put(`${apiRoot}proxy/user/${user_uuid}/outfits/${look_uuid}/hide`, null, {
            headers: { token: getUserToken() }
        }),
    removePhoto: (photo_uuid) =>
        axios.delete(`${apiRoot}proxy/user/photos/${photo_uuid}`, {
            headers: { token: getUserToken() }
        }),
    addItem: (item) =>
        axios.post(`${apiRoot}proxy/item`, item, { headers: { token: getUserToken() } }),
    closetTags: ({ user_uuid, params }) =>
        axios.get(`${apiRoot}proxy/user/${user_uuid}/closetTags`, {
            params,
            headers: { token: getUserToken() }
        }),
    deactivate: ({ user_uuid, token }) =>
        axios.put(`${apiRoot}proxy/user/${user_uuid}/deactivateAccount`, null, {
            headers: { token }
        })
};

export default User;
