import { captureException, init } from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import Config from './Config';

const isProduction = Config.isProd();
const sampleRate: number = isProduction ? 0.1 : 0.0;
const apiProvider = (requestUrl: string) => {
    const { hostname } = new URL(requestUrl);
    return hostname.toLowerCase().indexOf('api-gw.api.wishi.me') >= 0 ? 'wishi' : '3rdParty';
};

const initializeSentry = () => {
    init({
        dsn: Config.get('sentryDsn'),
        integrations: [BrowserTracing],
        beforeSend(event) {
            if (event.request?.url) {
                event.tags = event.tags || {};
                event.tags['apiProvider'] = apiProvider(event.request.url);
            }
            if (!isProduction) console.log(event);
            return event;
        },
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: sampleRate,
        environment: Config.getEnvironment()
    });
};

export const sentryException = (
    err: Error,
    customErrorName?: string,
    message?: string,
    contextDetails?: Record<string, any>
) => {
    if (customErrorName) err.name = customErrorName;
    if (message) err.message = message;
    if (contextDetails)
        captureException(err, {
            contexts: { ...contextDetails }
        });
    else captureException(err);
};

export default initializeSentry;
