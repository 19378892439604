import React, { useEffect, useState } from 'react';
import {
    Badge,
    ButtonGroup,
    Col,
    Container,
    Dropdown,
    DropdownButton,
    Image,
    Row
} from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Formatter } from 'services';
import {
    FavouritesFilterParams,
    FilterOptionsParameters,
    FilterParams,
    FilterStructInspiration,
    Gender,
    InspirationItem,
    InspirationQueryParameters,
    Item
} from 'types/styling';

import { Loader, SearchBox } from 'components';
import content from 'content.json';
import { Empty } from '../components';

const { styling } = content;

interface IInspiration {
    loading: boolean;
    loadInspiration: (input: InspirationQueryParameters) => void;
    loadInspirationTags: () => void;
    inspiration: InspirationItem[];
    filters: FilterStructInspiration[];
    updateFilters: (filter: FilterParams, isSelected: boolean, callback: () => void) => void;
    selectedFilters: Record<string, FilterOptionsParameters[]>;
    gender: Gender;
    updateGender: (gender: string) => void;
    addToCanvas: (item: InspirationItem) => void;
    loadStylistFavorites: (input: InspirationQueryParameters) => void;
    favorites: Item[];
    favorite: (favouritesFilter: FavouritesFilterParams) => void;
}

export const Inspiration: React.FC<IInspiration> = ({
    loading,
    loadInspiration,
    loadInspirationTags,
    inspiration,
    filters,
    updateFilters,
    selectedFilters,
    gender,
    updateGender,
    addToCanvas,
    loadStylistFavorites,
    favorites,
    favorite
}) => {
    const [expandSearch, setExpandSearch] = useState(false);
    const [favoritesParams, setFavoritesParams] = useState<Record<string, any>>({});

    useEffect(() => {
        if (gender) updateData();
    }, [gender]);

    useEffect(() => {
        if (Object.keys(selectedFilters).length > 0) updateData(selectedFilters);
    }, [selectedFilters]);

    const updateData = (filters = {}) => {
        loadStylistFavorites({ gender });
        loadInspirationTags();
        fetchData(filters);
    };

    const fetchData = (filters?: Record<string, FilterOptionsParameters[]>) => {
        const activeFilters = filters ?? selectedFilters;
        const params = Object.keys(activeFilters).reduce(
            (ac, a) =>
                activeFilters[a].length
                    ? {
                          ...ac,
                          [a]: activeFilters[a].map((selection) => selection.key).join(',')
                      }
                    : ac,
            {}
        );
        loadStylistFavorites({ ...params, from: 0, gender });
        setFavoritesParams({ ...params });
        loadInspiration({ ...params, from: !filters ? inspiration.length : 0 });
    };

    const onFilterChange = (filter: FilterParams) => {
        const isSelected =
            !!selectedFilters[filter.key] &&
            selectedFilters[filter.key].findIndex((item: FilterParams | FilterOptionsParameters) =>
                !Array.isArray(filter.value) ? item.key === filter.value.key : false
            ) > -1;
        updateFilters(filter, isSelected, fetchData);
    };

    const checkFavorite = (item: InspirationItem) =>
        favorites.findIndex(
            (favorite) =>
                favorite[favorite.originalItemUnique ? 'originalItemUnique' : 'item_uuid'] ===
                item.item_uuid
        ) > -1;

    return (
        <Container className="styling-room-inspiration">
            <Row>
                <Col id="results" className="results">
                    {loading && <Loader />}
                    <div className="results-bar">
                        <DropdownButton
                            key="inspiration-gender"
                            id="gender"
                            variant="secondary"
                            as={ButtonGroup}
                            title={styling.gender[gender]}>
                            {Object.keys(styling.gender).map((key: string) => (
                                <Dropdown.Item eventKey={key} key={key} onSelect={(eventKey) => eventKey && updateGender(eventKey)}>
                                    {styling.gender[key as Gender]}
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>

                        <p>
                            {styling.results.replace(
                                '%count%',
                                Formatter.number(inspiration.length)
                            )}
                        </p>

                        <SearchBox
                            className={expandSearch ? 'expanded' : ''}
                            options={filters.map((filter) => ({
                                value: filter.tag_uuid,
                                label: filter.tag_name
                            }))}
                            placeholder=""
                            onFocus={() => setExpandSearch(true)}
                            onBlur={() => setExpandSearch(false)}
                            onChange={(change) => {
                                if (change?.label) {
                                    onFilterChange({
                                        key: 'keywords',
                                        value: {
                                            key: change.label,
                                            value: change.label,
                                            text: change.label
                                        }
                                    });
                                }
                            }}
                        />
                        <div className="filter-badges">
                            {Object.keys(selectedFilters).map((key) =>
                                selectedFilters[key].map((filter) => (
                                    <Badge variant="light" key={filter.key}>
                                        <div
                                            className="close"
                                            onClick={() => onFilterChange({ key, value: filter })}
                                        />
                                        {filter.text}
                                    </Badge>
                                ))
                            )}
                        </div>
                    </div>
                    {inspiration.length ? (
                        <InfiniteScroll
                            dataLength={inspiration.length}
                            next={fetchData}
                            hasMore={true}
                            loader={undefined}>
                            <Container fluid>
                                <Row>
                                    {inspiration.map((item, index) => (
                                        <Col xs={3} key={item.uuid}>
                                            <div
                                                className={`fav-star ${
                                                    checkFavorite(item) ? 'filled' : 'empty'
                                                }`}
                                                onClick={() =>
                                                    favorite({
                                                        item,
                                                        attribute: 'uuid',
                                                        gender,
                                                        params: favoritesParams
                                                    })
                                                }
                                            />
                                            <Image
                                                src={item.picture}
                                                data-test-id={index}
                                                onClick={() =>
                                                    addToCanvas({ ...item, cropped: null })
                                                }
                                                style={{ width: 270 }}
                                            />
                                        </Col>
                                    ))}
                                </Row>
                            </Container>
                        </InfiniteScroll>
                    ) : (
                        <Empty tab="inspiration" />
                    )}
                </Col>
            </Row>
        </Container>
    );
};
