import { Actions as BookingActions, ActionTypes as BookingActionTypes } from './booking/types';
import { Actions as ErrorActions, ActionTypes as ErrorActionTypes } from './error/types';
import { Actions as ProfileActions, ActionTypes as ProfileActionTypes } from './profile/types';
import { Actions as RoomActions, ActionTypes as RoomActionTypes } from './room/types';
import { Actions as ServicesActions, ActionTypes as ServicesActionTypes } from './services/types';
import { Actions as UIActions, ActionTypes as UIActionTypes } from './ui/types';
import { Actions as UserActions, ActionTypes as UserActionTypes } from './user/types';

export type TActionTypes =
    | ErrorActionTypes
    | UserActionTypes
    | BookingActionTypes
    | UIActionTypes
    | ServicesActionTypes
    | RoomActionTypes
    | ProfileActionTypes;

export const ActionTypes = {
    ...ErrorActionTypes,
    ...UserActionTypes,
    ...BookingActionTypes,
    ...UIActionTypes,
    ...ServicesActionTypes,
    ...RoomActionTypes,
    ...ProfileActionTypes
};

export type Actions =
    | ErrorActions
    | UserActions
    | BookingActions
    | UIActions
    | ServicesActions
    | RoomActions
    | ProfileActions;
